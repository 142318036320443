import { useState } from 'react';
import styled from 'styled-components';

import { CircularProgress, MenuItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';

import { FormContainer } from '../FormContainer';
import { Props, TenantGroup } from './types';

export const SignIn: React.FC<Pick<Props, 'header' | 'onSignIn'>> = ({ header, onSignIn }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [tenantGroup, setTenantGroup] = useState<TenantGroup>('admin');
  const [error, setError] = useState('');

  return (
    <FormContainer
      preventDefaultOnSubmit={true}
      title={''}
      submitButtonLabel={
        <>
          {' '}
          {loading ? (
            <CircularProgress color="inherit" sx={{ marginBlock: '.5rem' }} size={20} />
          ) : (
            'SIGN IN'
          )}
        </>
      }
      onSubmit={async () => {
        setLoading(true);
        try {
          await onSignIn({ email, password, organizationName, tenantGroup });
          window.location.reload();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          setError(e.message);
        } finally {
          setLoading(false);
        }
      }}
      clearOnSubmit={false}
    >
      <Heading>{header}</Heading>
      <Box sx={{ width: '100%' }}>
        <TextField
          label="Organization"
          variant="standard"
          fullWidth
          value={organizationName}
          type={'text'}
          onChange={(e) => setOrganizationName(e.target.value)}
        />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={tenantGroup}
          label="Age"
          onChange={(event) => setTenantGroup(event.target.value as TenantGroup)}
        >
          <MenuItem value={'admin'}>Admin</MenuItem>
          <MenuItem value={'customer'}>Customer</MenuItem>
        </Select>
        <TextField
          label="Email"
          variant="standard"
          fullWidth
          value={email}
          type={'email'}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          variant="standard"
          fullWidth
          sx={{
            marginTop: '1.2rem',
            marginBottom: '2rem',
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <Error>{error}</Error>}
      </Box>
    </FormContainer>
  );
};

const Heading = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #344767;
  font-size: 1.2rem;
  line-height: 2rem;
`;

const Error = styled.p`
  color: red;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  padding-inline: 1rem;
  margin-block: 1rem;
  font-weight: 700;
`;
