/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import * as React from 'react';

import { FormControl } from '@mui/material';

import { Button } from '../Button';
import { Container } from '../Container';

export const FormContainer: React.FC<{
  children: React.ReactNode;
  title?: string;
  onSubmit?: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  submitButtonLabel: React.ReactNode;
  clearOnSubmit?: boolean;
  preventDefaultOnSubmit?: boolean;
  postFormChildren?: React.ReactNode;
}> = ({
  children,
  title,
  onSubmit = () => null,
  submitButtonLabel,
  clearOnSubmit = true,
  preventDefaultOnSubmit = false,
  postFormChildren,
}) => {
  const [key, setKey] = React.useState(1);
  return (
    <Container>
      <form
        onSubmit={async (e) => {
          preventDefaultOnSubmit && e.preventDefault();
          await onSubmit(e);
          clearOnSubmit && setKey(key + 1);
        }}
        key={key}
        style={{ paddingBottom: '32px' }}
      >
        <FormControl fullWidth sx={{ m: 1 }}>
          <h3>{title}</h3>
          {children}
          <br />
        </FormControl>
        <Button type="submit">{submitButtonLabel}</Button>
      </form>
      {postFormChildren}
    </Container>
  );
};
