import React from 'react';

type OnSignIn = (params: {
  email: string;
  password: string;
  organizationName: string;
  tenantGroup: 'admin' | 'customer';
}) => Promise<void>;

export interface InitializeSSOParams {
  SignInPage: React.FC<{
    onSignIn: OnSignIn;
    header: string;
    logoSrc: string;
  }>;
  logoSrc?: string;
  env: 'dev' | 'local';
  LoadingConfigScreen?: JSX.Element;
  localAuth?: boolean;
}

export type Config = InitializeSSOParams & {
  onSignIn: OnSignIn;
  getCurrentUserToken: () => Promise<{ token?: string; tenantId?: string }>;
  signOut: () => Promise<void> | void;
  getService: (service: string) => string;
};

export let config: Config;

export const setConfig = (value: Config) => {
  config = value;
};
