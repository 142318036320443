import 'firebase/compat/auth';

import firebase from 'firebase/compat/app';

import { getAppConfig, getOrganizationTenantIds } from '@cannabis/core/src/getAppConfig';

let currentFirebaseAuth: firebase.auth.Auth;

export const getFirebaseAuth = (params?: { tenantId?: string; localAuth?: boolean }) => {
  const firebaseAuth = currentFirebaseAuth ? currentFirebaseAuth : firebase.auth();
  if (params?.tenantId) {
    firebaseAuth.tenantId = params.tenantId;
  }
  console.log('params?.localAuth', params?.localAuth);
  if (params?.localAuth && !currentFirebaseAuth) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (firebaseAuth.useEmulator as any)('http://localhost:9099', { disableWarnings: true });
  }
  currentFirebaseAuth = firebaseAuth;
  return firebaseAuth;
};

const getTenantId = async ({
  tenantGroup,
  organizationName,
  env,
}: {
  tenantGroup: 'admin' | 'customer';
  organizationName: string;
  env: 'dev' | 'local';
}) => {
  const {
    data: { adminTenantId, customerTenantId },
  } = await getOrganizationTenantIds({ organizationName, env });

  switch (tenantGroup) {
    case 'admin':
      return adminTenantId;
    case 'customer':
      return customerTenantId;
  }
};

export const initializeFirebase = async ({
  // firebaseJson,
  env,
  localAuth,
}: {
  // firebaseJson: Record<string, string>;
  env: 'dev' | 'local';
  localAuth?: boolean;
}) => {
  const {
    data: { firebaseJson, callbackUrls },
  } = await getAppConfig(env);
  firebase.initializeApp(firebaseJson);

  const getService = (service: string) => callbackUrls[service];
  return {
    onSignIn: async ({
      email,
      password,
      organizationName,
      tenantGroup,
    }: {
      email: string;
      password: string;
      organizationName: string;
      tenantGroup: 'admin' | 'customer';
    }) => {
      if (organizationName) {
        const tenantId = await getTenantId({ tenantGroup, organizationName, env });
        console.log('tenantId', tenantId);
        await getFirebaseAuth({ tenantId, localAuth }).signInWithEmailAndPassword(email, password);
      } else {
        await getFirebaseAuth({ localAuth }).signInWithEmailAndPassword(email, password);
      }
    },
    getCurrentUserToken: () =>
      new Promise<{ token?: string; tenantId?: string }>((resolve) => {
        getFirebaseAuth({ localAuth }).onAuthStateChanged(async (user) => {
          if (user) {
            resolve({
              token: await user.getIdToken(),
              tenantId: user.tenantId as string,
            });
          } else {
            resolve();
          }
        });
      }),
    signOut: () => {
      const firebaseAuth = getFirebaseAuth({ localAuth });
      firebaseAuth.signOut();
    },
    getService,
  };
};
