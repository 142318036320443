import { Config, config } from './config';

import { pathInfo } from './pathInfo';

const getServicePath = (serviceKey: string) => {
  return config.getService(serviceKey);
};

export const initialize = async () => {
  const userAuth =
    (await config.getCurrentUserToken()) || {
      token: new URLSearchParams(window.location.search).get('token'),
      tenantId: new URLSearchParams(window.location.search).get('tenantId'),
    } ||
    '';
  const path = window.location.pathname;
  const { path: redirectPathInfo, service } = pathInfo.get();
  const singleSignOnService =
    new URLSearchParams(window.location.search).get('service') || service || '';
  const redirectPath =
    new URLSearchParams(window.location.search).get('path') || redirectPathInfo || '';
  switch (path) {
    // Signing in redirects to AWs
    case '/single-signon':
      if (userAuth.token) {
        pathInfo.set({});
        const redirectServiceUrl = getServicePath(singleSignOnService);
        window.location.replace(
          `${redirectServiceUrl || 'http://localhost:8001/'}${redirectPath}?token=${
            userAuth.token
          }&tenantId=${userAuth.tenantId}`,
        );
        return false;
      } else {
        pathInfo.set({ path, service: singleSignOnService });
        return true;
      }
    case '/sign-in':
      if (userAuth.token) {
        const redirectServiceUrl = getServicePath(service || '');
        window.location.replace(`${redirectServiceUrl}${redirectPath}?token=${userAuth.token}`);
        return false;
      } else {
        window.history.pushState(
          {},
          '',
          `${window.location.origin}/single-signon?service=${service}&path=${redirectPath}`,
        );
        return true;
      }
    // Called from an app when logging out
    case '/sign-out/':
    case '/sign-out':
      try {
        await config.signOut();
        await new Promise((resolve) => setTimeout(resolve, 400));
        const redirectSericeUrl = getServicePath(singleSignOnService);
        window.location.replace(`${redirectSericeUrl || 'http://localhost:8001/'}`);
        return true;
      } catch (e) {
        console.log('error signing out');
        return true;
      }
    default:
      window.history.pushState(
        {},
        '',
        `${window.location.origin}/single-signon?service=${singleSignOnService}&path=${redirectPath}`,
      );
      return true;
  }
};

export const onSignIn: Config['onSignIn'] = async (params) => {
  await config.onSignIn(params);
  window.location.reload();
};
