import axios from 'axios';
import firebase from 'firebase/compat/app';

import { AppDecodedToken } from '@cannabis/functions/middleware/authenticate';

import { getFirebaseAuth } from './firebase';

let localAuthMode = false;

export const getToken = async () =>
  new Promise<string | void>(async (resolve) => {
    const tenantId = organizationLocalStorage.get().adminTenantId;
    if (tenantId) {
      getFirebaseAuth({
        tenantId,
        localAuth: localAuthMode,
      }).onAuthStateChanged((user) => {
        if (user) {
          resolve(user.getIdToken());
          return;
        } else {
          resolve();
        }
      });
    } else {
      resolve();
    }
  });

export const organizationLocalStorage = {
  initialize: () => {
    const currentOrganization = organizationLocalStorage.get();
    if (!currentOrganization) {
      localStorage.setItem('organization', JSON.stringify({ tenantId: '', localAuth: false }));
    }
  },
  set: (organization: AppDecodedToken['organization']) =>
    localStorage.setItem('organization', JSON.stringify(organization)),
  get: (): AppDecodedToken['organization'] =>
    JSON.parse(localStorage.getItem('organization') || 'null'),
};

organizationLocalStorage.initialize();

export const deleteOrganizations = () => localStorage.removeItem('organizations');

const signIn =
  ({ baseUrl }: { baseUrl: string }) =>
  async ({ token, tenantId }: { token: string; tenantId: string }) => {
    try {
      const verifyTokenResult = await axios.get<
        unknown,
        {
          data: AppDecodedToken & { customToken: string };
        }
      >(baseUrl + '/custom-token', {
        headers: {
          Authorization: token,
          TenantId: tenantId,
        },
      });
      const { customToken, organization } = verifyTokenResult.data;
      organizationLocalStorage.set(organization);
      await getFirebaseAuth({ tenantId, localAuth: localAuthMode }).signInWithCustomToken(
        customToken,
      );
      return verifyTokenResult.data;
    } catch (e) {
      console.log('verifyToken', e);
      throw e;
    }
  };

const isSignedIn = async () =>
  new Promise<AppDecodedToken['organization'] | void>((resolve) => {
    const { adminTenantId } = organizationLocalStorage.get();
    getFirebaseAuth({ tenantId: adminTenantId, localAuth: localAuthMode }).onAuthStateChanged(
      (user) => {
        if (user) {
          resolve(organizationLocalStorage.get());
        } else {
          resolve();
        }
      },
    );
  });

const signOut = async () => {
  try {
    await getFirebaseAuth({ localAuth: localAuthMode }).signOut();
  } catch (e) {
    console.log('signOut error', e);
  }
};

export const manageUserSession = ({
  baseUrl,
  firebaseJson,
  localAuth,
}: {
  baseUrl: string;
  firebaseJson: Record<string, string>;
  localAuth?: boolean;
}) => {
  localAuthMode = !!localAuth;
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseJson);
  }
  return {
    signIn: signIn({ baseUrl }),
    isSignedIn,
    signOut,
  };
};
