import { SimplePaletteColorOptions, ThemeOptions } from '@mui/material/styles';

// ----------------------------------------------------------------------

// // SETUP COLORS
// const GREY = {
//   0: '#FFFFFF',
//   100: '#F9FAFB',
//   200: '#F4F6F8',
//   300: '#DFE3E8',
//   400: '#C4CDD5',
//   500: '#919EAB',
//   600: '#637381',
//   700: '#454F5B',
//   800: '#212B36',
//   900: '#161C24',
// };

const PRIMARY: SimplePaletteColorOptions = {
  light: '#90ff8e',
  main: '#59d65e',
  dark: '#10a42f',
  contrastText: '#fff',
};

const SECONDARY: SimplePaletteColorOptions = {
  light: '#fff',
  main: '#fff',
  dark: '#fff',
  contrastText: '#29228c',
};

// const INFO: PaletteColor = {
//   light: '#74CAFF',
//   main: '#1890FF',
//   dark: '#0C53B7',
//   contrastText: '#fff',
// };

// const SUCCESS: PaletteColor = {
//   light: '#AAF27F',
//   main: '#54D62C',
//   dark: '#229A16',
//   contrastText: GREY[800],
// };

// const WARNING: PaletteColor = {
//   light: '#FFE16A',
//   main: '#FFC107',
//   dark: '#B78103',
//   contrastText: GREY[800],
// };

// const ERROR = {
//   lighter: '#FFE7D9',
//   light: '#FFA48D',
//   main: '#FF4842',
//   dark: '#B72136',
//   darker: '#7A0C2E',
//   contrastText: '#fff',
// };

export const palette: ThemeOptions['palette'] = {
  primary: PRIMARY,
  secondary: SECONDARY,
  background: {
    default: '#F5F5F5',
  },
};

export default palette;
