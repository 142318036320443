import { InitializeSSOParams, setConfig } from './config';
import { useEffect, useState } from 'react';

import AppContainer from './App';
import { initializeFirebase } from '@cannabis/core/src';

export * from './initialize';

export const SSOApp = (params: InitializeSSOParams) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const run = async () => {
      const methods = await initializeFirebase({
        env: params.env,
        localAuth: params.localAuth,
      });
      setConfig({ ...params, ...methods });
      setLoading(false);
    };
    run();
  }, []);
  if (loading) {
    return params.LoadingConfigScreen || null;
  } else {
    return <AppContainer />;
  }
};
