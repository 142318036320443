/* eslint-disable max-len */
import * as React from 'react';

import Box from '@mui/material/Box';

export const Container: React.FC<{
  children: React.ReactNode;
  noBackground?: boolean;
}> = ({ children, noBackground }) => (
  <Box
    sx={{
      width: '100%',
      flexDirection: 'column',
      marginTop: '16px',
      backgroundColor: noBackground ? '' : 'white',
      borderRadius: ' 0.8rem',
      padding: '24px',
      boxShadow: noBackground ? '' : '1px 2px .5rem  #bdbdbd',
      paddingBottom: '4px',
    }}
  >
    {children}
  </Box>
);
