import { initialize, onSignIn } from '@tq1992/sso/initialize';
import { useEffect, useState } from 'react';

import { config } from '@tq1992/sso/config';

const AppContainer = () => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    const loadStore = async () => {
      setInitialized(await initialize());
    };
    loadStore();
  }, []);
  if (initialized) {
    return (
      <config.SignInPage
        header="Account Portal"
        onSignIn={onSignIn}
        logoSrc={config.logoSrc || ''}
      />
    );
  } else {
    return null;
  }
};

export default AppContainer;
