import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactDOM from 'react-dom/client';
import { SSOApp } from '@tq1992/sso';
import { SignInPage } from '@tq1992/components/singleSignOn/SignInPage';
import logoSrc from './Logo.png';

const env = import.meta.env.VITE_APP_APP_CONFIG_ENV;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <SSOApp
    {...{
      env: import.meta.env.VITE_APP_APP_CONFIG_ENV,
      SignInPage: SignInPage,
      logoSrc: logoSrc,
      organizationId: '6f23e90d-99da-42c1-a451-1b38d8188354',
      tenantGroup: 'admin',
    }}
    localAuth={env === 'local'}
  />,
);
