import axios from 'axios';

import { Env, appApiUrls } from './types';

export type AppConfigs = {
  callbackUrls: Record<string, string>;
  ssoUrl: string;
  apiUrl: string;
  firebaseJson: Record<string, string>;
  env: Env;
};

export let appConfig: AppConfigs;

export const getAppConfig = async (env: Env, localIpAddress?: string) => {
  const apiUrl =
    env === 'local' && localIpAddress ? `http://${localIpAddress}:5002/api` : appApiUrls[env];
  console.log('apiUrl', apiUrl);
  try {
    const result = await axios.get<unknown, { data: AppConfigs }>(`${apiUrl}/app-configs`);
    appConfig = result.data;
    return {
      ...result,
      data: {
        ...result.data,
        apiUrl,
      },
    };
  } catch (e) {
    console.log('error getting appConfig');
    throw e;
  }
};

export const getOrganizationTenantIds = ({
  env,
  organizationName,
}: {
  env: Env;
  organizationName: string;
}) => {
  const apiUrl = appApiUrls[env];
  return axios.get<unknown, { data: { adminTenantId: string; customerTenantId: string } }>(
    `${apiUrl}/organizations/tenant-ids?organizationName=${organizationName}`,
  );
};
